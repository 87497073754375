.input-group-text {
    padding: 0rem 0.5rem !important; 
}

.form-control {
    padding: 0.5rem 0.5rem !important; 
}

.timer-display {
    font-family: 'Digital', sans-serif;
    font-size: 2em;
    text-align: center;
}

.padding-unset {
    padding: unset !important; 
}

@keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.flash {
    animation: flash 1s infinite;
}

.navbar {
    padding: 0.75rem 0 !important; 
}

.uppercase {
    text-transform: uppercase !important; 
}

.install-banner {
    background-color: #f8f9fa; /* Light grey background */
    padding: 10px 0; /* Some padding */
    border-top: 1px solid #dee2e6; /* A top border */
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .app-icon {
    width: 50px; /* Adjust as needed */
    height: auto;
  }
  
  .close-button {
    font-size: 1.5rem;
    border: none;
    background: none;
  }

:root {
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;

    --bs-primary: #87a921 !important;
    --bs-secondary: #755820 !important;
    --bs-success: #d1dcb0 !important;
    --bs-info: #0dcaf0;
    --bs-warning: #f4c635;
    --bs-danger: #dc3545;
    --bs-light: #e6e9f2 !important;
    --bs-dark: #212529;

    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  }

  /* tea-green: #d1dcb0;
  apple-green: #87a921;
  saffron-yellow: #f4c635;
  field-drab: #755820;
  ghost-white: #e6e9f2;

  [data-bs-theme=light] {
    --bs-primary: #87a921 !important;
    --bs-secondary: #755820 !important;
    --bs-success: #d1dcb0 !important;
    --bs-info: #755820 !important;
    --bs-warning: #f4c635 !important;
    --bs-danger: #d9534f;
    --bs-light: #e6e9f2 !important;
    --bs-dark: #343a40;
  } */